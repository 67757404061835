<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { getTranslatedProperty } from "@shopware/helpers";

const { t } = useI18n();
const { getCountries } = useCountries();
const { getSalutations } = useSalutations();

interface stateStepRules {
  title: {};
  name: {};
  street: {};
  zipcode: {};
  city: {};
  countryid: {};
  mail: {};
}

const props = defineProps<{
  state: {
    title: string;
    customernumber?: string;
    name: string;
    street: string;
    zipcode: string;
    city: string;
    countryid: string;
    phone?: string;
    mail: string;
    reference?: string;
  };
  rules: stateStepRules;
}>();

const { rules, state } = toRefs(props);

// Rules
const stateRules = reactive({
  ...rules.value,
});
const v1 = useVuelidate(stateRules, state);

// const isCustomerNumberValid = ref();
// const isLoading = ref(false);
// async function validateCustomerNumber() {
//   isCustomerNumberValid.value = false;
//   if (state.value.customernumber?.length > 0) {
//     try {
//       isLoading.value = true;

//       let validation = await useFetch(
//         `/api/contact/customer/${state.value.customernumber}`,
//       );
//       if (validation.data.value) {
//         isCustomerNumberValid.value = true;
//       }
//       if (!isLoading && !isCustomerNumberValid.value) {
//         state.value.customernumber = "";
//       }
//     } catch (error) {
//       console.error("Error during API call:", error);
//       isCustomerNumberValid.value = false;
//       isLoading.value = false;
//     }

//     isLoading.value = false;
//   }
// }

onMounted(() => {
  v1.value.$reset();
  // validateCustomerNumber();
});
</script>

<template>
  <div class="w-full sm:w-1/2">
    <label
      for="selectSalutation"
      class="c-scheppach-primary-500 text-sm md:text-base mb--3"
    >
      {{ t("form.salutation") }} *
    </label>
    <select
      id="selectSalutation"
      v-model="state.title"
      required
      class="placeholder-text-scheppach-neutral-500 text-scheppach-primary-500 bg-scheppach-neutral-50 p-3 outline-none w-full b-b"
      :class="[
        v1.title.$error ? 'b-scheppach-error-500' : 'b-scheppach-primary-700',
      ]"
      @blur="v1.title.$touch()"
    >
      <option disabled selected value="">
        {{ t("form.chooseSalutation") }}
      </option>
      <option
        v-for="item in getSalutations"
        :key="item.displayName"
        :value="item.displayName"
      >
        {{ item.displayName }}
      </option>
    </select>
    <span v-if="v1.title.$error" class="text-sm c-scheppach-error-500">
      {{ v1.title?.$errors[0]?.$message }}
    </span>
  </div>

  <ScheppachTextInput
    v-model="state.name"
    :label="t('form.nameAndLastname')"
    :required="true"
    :placeholder="t('form.namePlaceholder')"
    :input-error="v1.name?.$error"
    :input-error-message="v1.name?.$errors[0]?.$message"
    @blur="v1.name?.$touch()"
  />

  <ScheppachTextInput
    v-model="state.street"
    :label="t('form.street')"
    :required="true"
    :placeholder="t('form.streetPlaceholder')"
    :input-error="v1.street?.$error"
    :input-error-message="v1.street?.$errors[0]?.$message"
    @blur="v1.street?.$touch()"
  />

  <div class="flex flex-col sm:flex-row gap-3">
    <ScheppachTextInput
      v-model="state.zipcode"
      :required="true"
      :label="t('form.postalCode')"
      class="w-full sm:w-1/3"
      :placeholder="t('form.postalCodePlaceholder')"
      :input-error="v1.zipcode?.$error"
      :input-error-message="v1.zipcode?.$errors[0]?.$message"
      @blur="v1.zipcode?.$touch()"
    />
    <ScheppachTextInput
      v-model="state.city"
      :required="true"
      :label="t('form.city')"
      class="w-full sm:w-2/3"
      :placeholder="t('form.cityPlaceholder')"
      :input-error="v1.city?.$error"
      :input-error-message="v1.city?.$errors[0]?.$message"
      @blur="v1.city?.$touch()"
    />
  </div>

  <div>
    <label
      for="selectCountry"
      class="text-scheppach-primary-500 text-sm md:text-base mb--3"
    >
      {{ t("form.country") }} *
    </label>
    <select
      id="selectCountry"
      v-model="state.countryid"
      required
      class="placeholder-text-scheppach-neutral-500 text-scheppach-primary-500 bg-scheppach-neutral-50 p-3 outline-none w-full b-b"
      :class="[
        v1.countryid.$error
          ? 'b-scheppach-error-500'
          : 'b-scheppach-primary-700',
      ]"
      @blur="v1.countryid.$touch()"
    >
      <option disabled selected value="">
        {{ t("form.chooseCountry") }}
      </option>
      <option
        v-for="country in getCountries"
        :key="country.id"
        :value="country.iso"
      >
        {{ getTranslatedProperty(country, "name") }}
      </option>
    </select>
    <span v-if="v1.countryid.$error" class="text-sm c-scheppach-error-500">
      {{ v1.countryid.$errors[0]?.$message }}
    </span>
  </div>

  <ScheppachTextInput
    v-model="state.phone"
    :label="t('form.phoneNumber')"
    :placeholder="t('form.optional')"
    :type="'tel'"
    :input-error="v1.phone?.$error"
    :input-error-message="v1.phone?.$errors[0]?.$message"
    @blur="v1.phone?.$touch()"
  />

  <ScheppachTextInput
    v-model="state.mail"
    :required="true"
    :type="'email'"
    :label="t('form.email')"
    :placeholder="t('form.emailPlaceholder')"
    :input-error="v1.mail?.$error"
    :input-error-message="v1.mail?.$errors[0]?.$message"
    @blur="v1.mail?.$touch()"
  />

  <ScheppachTextInput
    v-model="state.reference"
    :label="t('form.contactForm.comissionReference')"
    :placeholder="t('form.contactForm.comissionReferencePlaceholder')"
  />
</template>
